<template>
	<CustomTable
		:recent-filter="recentFilters"
		:total-pages="totalPages"
		:total-items="totalItems"
		:label-search="$t('EditorPayrollFilter.search')"
		:label-date="$t('EditorPayrollFilter.completeDate')"
		:show-filter-customers="true"
		:show-filter-editor="true"
		:show-filter-amount="true"
		:show-export-button="showButtonExport"
		:show-total-editor="true"
		:total-editor="totalEditorWage"
		table-name="editor-payroll"
		:amounts="amountOptions"
		:customers="customers"
		:editors="editors"
		@search="search"
		@exportExcel="exportExcel"
	>
		<template #headers>
			<tr>
				<th
					v-for="(header, index1) in headers"
					:key="`${header.title}-${index1}`"
					scope="col"
					:class="`${header.class}`"
				>
					{{ header.title }}
				</th>
			</tr>
		</template>
		<template #body>
			<template v-if="editorPayroll && editorPayroll.length">
				<tr v-for="(item, index2) in editorPayroll" :key="index2">
					<td scope="row" class="text-break text-center">
						{{ convertDateToDateVN(item.completeDate) }}
					</td>
					<td scope="row" class="text-break text-center">
						{{ item.updateId }}
					</td>
					<td scope="row" class="text-break text-center">
						{{ item.orderId }}
					</td>
					<td scope="row" class="text-break text-center">
						{{ item.customerName }}
					</td>
					<td scope="row" class="text-break text-center see-more">
						{{ item.title }}
					</td>
					<td scope="row" class="text-break text-center">
						{{ convertDateToDateVN(item.deadline) }}
					</td>
					<td scope="row" class="text-break text-center">
						{{ convertUTCtoDateVN(item.receivedTime) }}
					</td>
					<td scope="row" class="text-break text-center">
						{{ convertAmount(item.amounts, "amount") }}
					</td>
					<td scope="row" class="text-break text-center">
						{{ convertAmount(item.amounts, "missing-amount") }}
					</td>
					<td scope="row" class="text-break text-center">
						{{ item.editorNote }}
					</td>
					<td scope="row">
						<SelectEditor :id="item.updateId" :item="item" :amounts="item.amounts" />
					</td>
					<td scope="row" class="text-break text-center note">
						<NoteEditor :id="item.updateId" :index="index2" :value="item.note" @save="handleNote" />
					</td>
				</tr>
			</template>
			<template v-else>
				<tr class="text-center">
					<td colspan="11" class="fit">
						{{ $t("Table.NoData") }}
					</td>
				</tr>
			</template>
		</template>
	</CustomTable>
</template>

<script>
import { isArray } from "lodash-es"
import { createNamespacedHelpers } from "vuex"
import { AMOUNT_OPTIONS, PARAMS } from "../../../shared/plugins/constants"
import { GET_CUSTOMERS } from "../../customers/store/action-types"
import { SET_VARIABLE } from "../../customers/store/mutation-types"
import { GET_EDITORS } from "../../editors/store/action-types"
import { GET_EDITOR_PAYROLL, EXPORT_EXCEL_PAYROLL, SAVE_NOTE } from "../store/action-types"
import { RESET_STATE_EDITOR_PAYROLL } from "../store/mutation-types"
const { mapState, mapActions, mapMutations } = createNamespacedHelpers("editorPayroll")
const { mapActions: editorsActions, mapGetters: editorsGetters } = createNamespacedHelpers(
	"editors",
)

const {
	mapActions: customersActions,
	mapState: customersState,
	mapMutations: customersMutations,
} = createNamespacedHelpers("customers")
export default {
	name: "EditorPayrollManagement",
	components: {
		SelectEditor: () => import("../componetns/SelectEditor"),
		NoteEditor: () => import("../componetns/NoteEditor"),
	},
	data() {
		return {
			limit: PARAMS.LIMIT,
			offset: PARAMS.OFFSET,
			keyword: null,
			headers: [
				{
					title: this.$t("EditorPayrollFieldTable.CompleteDate"),
					class: "text-nowrap text-center date",
				},
				{
					title: this.$t("EditorPayrollFieldTable.UpdateID"),
					class: "text-center text-nowrap email",
				},
				{
					title: this.$t("EditorPayrollFieldTable.OrderID"),
					class: "text-center text-nowrap email",
				},
				{
					title: this.$t("EditorPayrollFieldTable.CustomerName"),
					class: "text-nowrap text-center name",
				},
				{
					title: this.$t("EditorPayrollFieldTable.Title"),
					class: "text-nowrap title text-center",
				},
				{
					title: this.$t("EditorPayrollFieldTable.Deadline"),
					class: "text-nowrap text-center date",
				},
				{
					title: this.$t("EditorPayrollFieldTable.ReceivedTime"),
					class: "text-nowrap text-center date",
				},
				{
					title: this.$t("EditorPayrollFieldTable.Amount"),
					class: "text-nowrap text-center amount",
				},
				{
					title: this.$t("EditorPayrollFieldTable.MissingAmount"),
					class: "text-nowrap text-center fit",
				},
				{
					title: this.$t("EditorPayrollFieldTable.EditorNote"),
					class: "text-nowrap text-center date",
				},
				{
					title: this.$t("OrderFieldTable.Editor"),
					class: "text-nowrap text-center editor-options",
				},
				{
					title: this.$t("OrderFieldTable.Note"),
					class: "text-nowrap text-center note",
				},
			],
			amountOptions: [
				{
					name: this.$t("EditorPayrollFilter.Yes"),
					value: AMOUNT_OPTIONS.YES,
				},
				{
					name: this.$t("EditorPayrollFilter.No"),
					value: AMOUNT_OPTIONS.NO,
				},
			],
			editorsSelected: {},
			editor: {},
			isEdit: {},
		}
	},
	computed: {
		...mapState(["recentFilters", "totalPages", "totalItems", "editorPayroll", "totalEditorWage"]),
		...customersState(["customers"]),
		...editorsGetters(["editors"]),
		showButtonExport() {
			if (this.editorPayroll && this.editorPayroll.length) {
				return true
			}
			return false
		},
	},
	created() {
		this.setupData()
	},
	beforeDestroy() {
		this.RESET_STATE_EDITOR_PAYROLL()
	},
	methods: {
		...mapActions({ GET_EDITOR_PAYROLL, EXPORT_EXCEL_PAYROLL, SAVE_NOTE }),
		...customersActions({ GET_CUSTOMERS }),
		...customersMutations({ SET_VARIABLE }),
		...editorsActions({ GET_EDITORS }),
		...mapMutations({ RESET_STATE_EDITOR_PAYROLL }),
		async setupData() {
			await Promise.all[(this.getCustomers(), this.getEditors())]
		},
		async search(params) {
			if (params.customerIds && !isArray(params.customerIds)) {
				params["customerIds"] = [params.customerIds]
			}
			if (params.editorIds && !isArray(params.editorIds)) {
				params["editorIds"] = [params.editorIds]
			}
			await this.GET_EDITOR_PAYROLL(params)
		},
		async getCustomers() {
			const params = {
				notLoading: true,
			}
			return await this.GET_CUSTOMERS(params)
		},
		async getEditors() {
			const params = {
				notLoading: true,
				isSort: true,
			}
			await this.GET_EDITORS(params)
		},
		async exportExcel() {
			const params = {
				...this.recentFilters,
				isExport: true,
			}
			delete params.limit
			delete params.offset
			await this.EXPORT_EXCEL_PAYROLL(params)
		},
		convertAmount(amounts, type) {
			let amount = ""
			if (!amounts?.length) return null

			amounts.forEach(el => {
				if (type === "amount") {
					if (!el.amount) {
						if (amount) {
							amount = `${amount}`
						} else {
							amount = +`${amount}`
						}
					} else if (amount) {
						amount = `${amount}+${el.amount}${el.orderTypeId}`
					} else {
						amount = `${el.amount}${el.orderTypeId}`
					}
				} else {
					if (!el.missingAmount) {
						if (amount) {
							amount = `${amount}`
						} else {
							amount = +`${amount}`
						}
					} else if (amount) {
						amount = `${amount}+${el.missingAmount}${el.orderTypeId}`
					} else {
						amount = `${el.missingAmount}${el.orderTypeId}`
					}
				}
			})

			return amount
		},
		async handleNote(params) {
			await this.SAVE_NOTE(params)
		},
	},
}
</script>
<style lang="scss" scoped>
.editor-options {
	min-width: 250px;
}
</style>
<style lang="scss">
.customer-multiselect {
	.multiselect__tags {
		max-height: 100px;
		overflow-y: auto;
	}
}
</style>
